import {
    BoardStatKeys,
    BoardStats,
    BridgePosition,
    ChatEntry,
    DisplayPosition,
    GamePhase,
    IBid,
    ICard,
    ICards,
    IGameResults,
    IGameResultsTravelerV2,
    IGameResultsV2,
    initialBaseSeatData,
    ISeatData,
    ITableResult,
    Vulnerability
} from '../_models/models';
import { getDummyBridgePosition, getRotatedSeatData, setUniqueSeatDataAttribute } from '../_utils/helper';
import { getInitialCards } from '../_utils/initialHelper';
import { PossibleButton } from '../settings/options';

export const ACTION_TYPES = {
    ADD_CHAT_ENTRY: 'Game/ADD_CHAT_ENTRY',
    APPEND_AUCTION: 'Game/APPEND_AUCTION',
    BIDDING_DISABLE_ALL: 'Game/BIDDING_DISABLE_ALL',
    BIDDING_DISABLE_TO: 'Game/BIDDING_DISABLE_TO',
    CLEAR_CHAT: 'Game/CLEAR_CHAT',
    CLEAR_MY_HIGHLIGHTED_CARDS: 'Game/CLEAR_MY_HIGHLIGHTED_CARDS',
    CLEAR_SYSTEM_CHAT: 'Game/CLEAR_SYSTEM_CHAT',
    CLOSE_TRICK: 'Game/CLOSE_TRICK',
    DEAL_HANDS: 'Game/DEAL_HAND',
    DISPLAY_ACTIVE_SEAT: 'Game/DISPLAY_ACTIVE_SEAT',
    ENABLE_DUMMY: 'Game/ENABLE_DUMMY',
    FORCE_HIDE_ME: 'Game/FORCE_HIDE_ME',
    APPLY_GAME_HISTORY_ELEMENT: 'Game/APPLY_GAME_HISTORY_ELEMENT',
    INIT: 'Game/INIT',
    INJECT_TRICK_HISTORY: 'Game/INJECT_TRICK_HISTORY',
    MAKE_PLAY: 'Game/MAKE_PLAY',
    RAISE_CARDS: 'Game/RAISE_CARDS',
    RESET: 'Game/RESET',
    RESET_EXCEPT: 'Game/RESET_EXCEPT',
    RESET_PARTIAL: 'Game/RESET_PARTIAL',
    ROTATE_TABLE: 'Game/ROTATE_TABLE',
    SET_ACTIVE_SEAT: 'Game/SET_ACTIVE_SEAT',
    SET_AUCTION: 'Game/SET_AUCTION',
    SET_BOOLEAN: 'Game/SET_BOOLEAN',
    SET_BOARD: 'Game/SET_BOARD',
    SET_BOARD_STATS: 'Game/SET_BOARD_STATS',
    SET_BPN_RECORD: 'Game/SET_BPN_RECORD',
    SET_CONTRACT: 'Game/SET_CONTRACT',
    SET_CONVENTION_CARD: 'Game/SET_CONVENTION_CARD',
    SET_DEALER: 'Game/SET_DEALER_POSITION',
    SET_DIRECTOR_ISSUE: 'Game/SET_DIRECTOR_ISSUE',
    SET_DIRECTOR_ISSUE_DONE: 'Game/SET_DIRECTOR_ISSUE_DONE',
    SET_DOUBLE_VALID: 'Game/SET_DOUBLE_VALID',
    SET_DUMMY_CARDS: 'Game/SET_DUMMY_CARDS',
    SET_FOREIGN_BOARD_REVIEW_DATA: 'Game/SET_FOREIGN_BOARD_REVIEW_DATA',
    SET_FOREIGN_BOARD_REVIEW_PLAYER_DATA: 'Game/SET_FOREIGN_BOARD_REVIEW_PLAYER_DATA',
    SET_GAME_PHASE: 'Game/SET_GAME_PHASE',
    SET_GAME_RESULTS: 'Game/SET_GAME_RESULTS',
    SET_GAME_RESULTS_V2: 'Game/SET_GAME_RESULTS_V2',
    SET_IFRAME: 'Game/SET_IFRAME',
    SET_IS_HIGHLIGHTED: 'Game/SET_IS_HIGHLIGHTED',
    SET_IS_INTERACTIVE: 'Game/SET_IS_INTERACTIVE',
    SET_IS_BLOCKED_BY_TEACHER: 'Game/SET_IS_BLOCKED_BY_TEACHER',
    SET_IS_VISIBLE: 'Game/SET_IS_VISIBLE',
    SET_JITSI: 'Game/SET_JITSI',
    SET_JITSI_FULLSIZE: 'Game/SET_JITSI_FULLSIZE',
    SET_MANUAL_DEAL: 'Game/SET_MANUAL_DEAL',
    SET_META_DATA: 'Game/SET_META_DATA',
    SET_MY_BRIDGE_POSITION: 'Game/SET_MY_BRIDGE_POSITION',
    SET_MY_BOARD_STAT_KEYS: 'Game/SET_MY_BOARD_STAT_KEYS',
    SET_MY_HIGHLIGHTED_CARDS: 'Game/SET_MY_HIGHLIGHTED_CARDS',
    SET_PLAYER_NAMES: 'Game/SET_PLAYER_NAMES',
    SET_SPECTATOR: 'Game/SET_SPECTATOR',
    SET_TABLE_ID: 'Game/SET_TABLE_ID',
    SET_TRICK_COUNT: 'Game/SET_TRICK_COUNT',
    SET_TWITCH: 'Game/SET_TWITCH',
    SET_VIDEO: 'Game/SET_VIDEO',
    SET_VULNERABILITY: 'Game/SET_VULNERABILITY',
    SET_WINNERS: 'Game/SET_WINNERS',
    SHOW_BUTTONS: 'Game/SHOW_BUTTONS',
    TIME_TO_TOURNAMENT: 'Game/TIME_TO_TOURNAMENT',
    TIME_TO_GAME_END: 'Game/TIME_TO_GAME_END',
    UNRAISE_CARDS: 'Game/UNRAISE_CARDS'
};

// Reducer

export type ForeignBoardReviewPlayerData = Pick<IGameState, 'auction' | 'tricks' | 'winner'> & {
    uuid: IGameResultsTravelerV2['uuid'];
    declarer: BridgePosition | undefined;
};

export type ForeignBoardReviewData = Pick<
    IGameState,
    // for short data
    | 'cards'
    | 'seatData'
    | 'contract'
    | 'gamePhase'

    // for auction box
    | 'vulnerability'
    | 'showAuctionBox'

    // for board stats
    | 'boardStats'
    | 'myBoardStatKeys'
> & {
    playerData: ForeignBoardReviewPlayerData[];
};

export interface IGameState {
    activeBridgePosition?: BridgePosition;
    auction: IBid[];
    biddings: {
        disabled: boolean;
    };
    board?: {
        label?: string;
        value?: string;
        boardNumber?: number;
    };
    boardStats: BoardStats[];
    bpnRecord?: string;
    buttons: PossibleButton[];
    cards: ICards;
    chat: ChatEntry[];
    contract?: IBid;
    conventionCards: {
        ns?: string;
        ew?: string;
        url?: string;
    };
    conventionCardUrlSet: boolean;
    directorIssue: string;
    directorIssueDone: boolean;
    doubleValid: number;
    dummyEnabled: boolean;
    forceHideMe: boolean;
    foreignBoardReviewData?: ForeignBoardReviewData;
    gamePhase: GamePhase;
    gameResults?: IGameResults;
    gameResultsV2?: IGameResultsV2;
    iframe?: string;
    isSpectator: boolean;
    isBlockedByTeacher: boolean;
    jitsi: {
        configOverwrite?: any;
        domain?: string;
        fullSize?: boolean;
        interfaceConfigOverwrite?: any;
        password?: string;
        roomName?: string;
        userName?: string;
        breakOut?: string;
        jwt?: string;
        goToBreakout?: boolean;
    };
    metaData: {
        director?: string;
        eventName?: string;
        hostName?: string;
        resultUrl?: string;
        round?: string;
        tableResults?: ITableResult[];
        welcomeMessage?: string;
        comment?: string;
        timetoround?: Date;
        isSupervised?: boolean;
        isBiddingQuiz?: boolean;
    };
    myBoardStatKeys: BoardStatKeys;
    myHighlightedCards: ICard['id'][];
    seatData: {
        [bridgePosition: string]: ISeatData;
    };
    showAuctionBox: boolean;
    showChatSendForm: boolean;
    showClosedTricks: boolean;
    showInvites: boolean;
    showTrickHistory: boolean;
    stake?: IBid;
    tableId?: string;
    timeToTournament?: number;
    timeToGameEnd?: number;
    trickCount: {
        ns: number;
        ew: number;
    };
    tricks: ICard[][];
    twitch: {
        channel?: string;
        configOverwrite?: any;
        fullSize?: boolean;
    };
    video: {
        micro?: boolean;
        sound?: boolean;
        camera?: boolean;
    };
    vulnerability: Vulnerability;
    winner: BridgePosition[];
}

export const initialGameState: IGameState = {
    activeBridgePosition: undefined,
    auction: [],
    biddings: {
        disabled: false
    },
    board: undefined,
    boardStats: [],
    bpnRecord: undefined,
    buttons: ['claim', 'undo', 'download', 'next', 'prev'],
    cards: getInitialCards(),
    chat: [],
    contract: undefined,
    conventionCards: {
        ns: undefined,
        ew: undefined
    },
    conventionCardUrlSet: false,
    directorIssue: '',
    directorIssueDone: false,
    doubleValid: 1,
    dummyEnabled: false,
    forceHideMe: false,
    foreignBoardReviewData: undefined,
    gamePhase: GamePhase.PRE,
    gameResults: undefined,
    gameResultsV2: undefined,
    // gameResults: {
    //   "Game": "a92ecc8c-c99b-4713-ba3b-e0692de54f01",
    //   "TimeStamp": 1607713343,
    //   "Movement": "Mitchell",
    //   "Scoring": "MP",
    //   "ShowRanks": true,
    //   "UUID": "a92ecc8c-c99b-4713-ba3b-e0692de54f01",
    //   "Pairs": [
    //     {
    //       "p1": "MMS2",
    //       "p2": "MMS1",
    //       "traveler": [
    //         {
    //           "declarer": "w",
    //           "tricksResult": "-1",
    //           "contract": "4h",
    //           "boardindex": 0,
    //           "lead": "da",
    //           "boardnumber": 1,
    //           "UUID": "a9e5cf04-3af2-4537-80fc-c92e7b7dfa35",
    //           "dir": "ns",
    //           "score": 50,
    //           "vsPairId": "1",
    //           "vs": "MMS3 / MMS4",
    //           "resultMP": 0,
    //           "runningMP": -999,
    //           "resultIMP": -12,
    //           "runningIMP": -999
    //         }
    //       ],
    //       "runningMP": -999,
    //       "runningIMP": -999,
    //       "rankMP": "0",
    //       "rankIMP": "0",
    //       "pairId": "0",
    //       "dir": "ns"
    //     },
    //     {
    //       "p1": "MMS3",
    //       "p2": "MMS4",
    //       "traveler": [
    //         {
    //           "declarer": "w",
    //           "tricksResult": "-1",
    //           "contract": "4h",
    //           "boardindex": 0,
    //           "lead": "da",
    //           "boardnumber": 1,
    //           "UUID": "a9e5cf04-3af2-4537-80fc-c92e7b7dfa35",
    //           "dir": "ew",
    //           "score": -50,
    //           "vsPairId": "0",
    //           "vs": "MMS2 / MMS1",
    //           "resultMP": 100,
    //           "runningMP": -999,
    //           "resultIMP": 12,
    //           "runningIMP": -999
    //         }
    //       ],
    //       "runningMP": -999,
    //       "runningIMP": -999,
    //       "rankMP": "0",
    //       "rankIMP": "0",
    //       "pairId": "1",
    //       "dir": "ew"
    //     },
    //     {
    //       "p1": "MMS6",
    //       "p2": "MMS5",
    //       "traveler": [
    //         {
    //           "declarer": "e",
    //           "tricksResult": "-13",
    //           "contract": "7nt",
    //           "boardindex": 0,
    //           "lead": "",
    //           "boardnumber": 1,
    //           "UUID": "febd5fdb-232f-4030-a6f6-39b817f7ca9e",
    //           "dir": "ns",
    //           "score": 650,
    //           "vsPairId": "3",
    //           "vs": "MMS7 / woy",
    //           "resultMP": 100,
    //           "runningMP": -999,
    //           "resultIMP": 12,
    //           "runningIMP": -999
    //         }
    //       ],
    //       "runningMP": -999,
    //       "runningIMP": -999,
    //       "rankMP": "0",
    //       "rankIMP": "0",
    //       "pairId": "2",
    //       "dir": "ns"
    //     },
    //     {
    //       "p1": "MMS7",
    //       "p2": "woy",
    //       "traveler": [
    //         {
    //           "declarer": "e",
    //           "tricksResult": "-13",
    //           "contract": "7nt",
    //           "boardindex": 0,
    //           "lead": "",
    //           "boardnumber": 1,
    //           "UUID": "febd5fdb-232f-4030-a6f6-39b817f7ca9e",
    //           "dir": "ew",
    //           "score": -650,
    //           "vsPairId": "2",
    //           "vs": "MMS6 / MMS5",
    //           "resultMP": 0,
    //           "runningMP": -999,
    //           "resultIMP": -12,
    //           "runningIMP": -999
    //         }
    //       ],
    //       "runningMP": -999,
    //       "runningIMP": -999,
    //       "rankMP": "0",
    //       "rankIMP": "0",
    //       "pairId": "3",
    //       "dir": "ew"
    //     }
    //   ]
    // },
    iframe: undefined,
    isSpectator: false,
    isBlockedByTeacher: false,
    jitsi: {
        configOverwrite: undefined,
        domain: undefined,
        fullSize: false,
        interfaceConfigOverwrite: undefined,
        password: undefined,
        roomName: undefined,
        userName: undefined
    },
    metaData: {
        director: undefined,
        eventName: undefined,
        hostName: undefined,
        resultUrl: undefined,
        round: undefined,
        tableResults: [],
        welcomeMessage: undefined,
        comment: undefined,
        timetoround: undefined,
        isSupervised: true,
        isBiddingQuiz: false
    },
    myBoardStatKeys: {
        r: 0,
        s: 0,
        c: 0,
        d: 0,
        x: 0,
        l: 0
    },
    myHighlightedCards: [],
    seatData: {
        north: {
            ...initialBaseSeatData,
            bridgePosition: BridgePosition.north,
            displayPosition: DisplayPosition.top
        },
        east: {
            ...initialBaseSeatData,
            bridgePosition: BridgePosition.east,
            displayPosition: DisplayPosition.right
        },
        south: {
            ...initialBaseSeatData,
            bridgePosition: BridgePosition.south,
            displayPosition: DisplayPosition.bottom
        },
        west: {
            ...initialBaseSeatData,
            bridgePosition: BridgePosition.west,
            displayPosition: DisplayPosition.left
        }
    },
    showAuctionBox: true,
    showChatSendForm: true,
    showClosedTricks: true,
    showInvites: false,
    showTrickHistory: false,
    stake: undefined,
    tableId: undefined,
    timeToTournament: undefined,
    timeToGameEnd: undefined,
    trickCount: {
        ns: 0,
        ew: 0
    },
    tricks: [],
    twitch: {
        channel: undefined,
        configOverwrite: undefined,
        fullSize: undefined
    },
    video: {
        micro: false,
        sound: true,
        camera: true
    },
    vulnerability: Vulnerability.none,
    winner: []
};

export default (state: IGameState = initialGameState, action: any): IGameState => {
    switch (action.type) {
        case ACTION_TYPES.ADD_CHAT_ENTRY: {
            const { chatEntry }: { chatEntry: ChatEntry } = action.payload || {};
            return {
                ...state,
                chat: [...state.chat, chatEntry]
            };
        }

        case ACTION_TYPES.APPEND_AUCTION: {
            const { call }: { call: IBid } = action.payload;
            return {
                ...state,
                auction: [...state.auction, call]
            };
        }

        case ACTION_TYPES.BIDDING_DISABLE_ALL: {
            return {
                ...state
            };
        }

        case ACTION_TYPES.CLEAR_CHAT: {
            return {
                ...state,
                chat: initialGameState.chat
            };
        }

        case ACTION_TYPES.CLEAR_SYSTEM_CHAT: {
            return {
                ...state,
                chat: [...state.chat.filter(chatEntry => !chatEntry.isSystemMessage)]
            };
        }

        case ACTION_TYPES.CLEAR_MY_HIGHLIGHTED_CARDS: {
            return {
                ...state,
                myHighlightedCards: initialGameState.myHighlightedCards
            };
        }

        case ACTION_TYPES.CLOSE_TRICK: {
            const { winner }: { winner: BridgePosition } = action.payload;
            return {
                ...state,
                winner: [...state.winner, winner]
            };
        }

        case ACTION_TYPES.DEAL_HANDS: {
            const { hands }: { hands: { [bridgePosition: string]: Array<ICard['id']> } } = action.payload;
            const cards = { ...state.cards };
            Object.keys(hands).forEach(bridgePosition => {
                hands[bridgePosition].forEach(cardId => {
                    cards[cardId] = { ...cards[cardId], bridgePosition: bridgePosition as BridgePosition, position: 'hand' };
                });
            });
            return {
                ...state,
                cards
            };
        }

        case ACTION_TYPES.DISPLAY_ACTIVE_SEAT: {
            const { activeSeat }: { activeSeat?: BridgePosition } = action.payload;
            return {
                ...state,
                activeBridgePosition: activeSeat,
                seatData: setUniqueSeatDataAttribute(
                    { ...state.seatData },
                    { isActive: !!activeSeat, isHighlighted: !!activeSeat },
                    activeSeat
                )
            };
        }

        case ACTION_TYPES.ENABLE_DUMMY: {
            const { dummyEnabled }: { dummyEnabled: boolean } = action.payload;
            const dummy = getDummyBridgePosition({ ...state.seatData });
            return {
                ...state,
                seatData: dummy
                    ? setUniqueSeatDataAttribute({ ...state.seatData }, { isDummy: dummyEnabled }, dummy)
                    : { ...state.seatData },
                dummyEnabled
            };
        }

        case ACTION_TYPES.FORCE_HIDE_ME: {
            return {
                ...state,
                forceHideMe: action.payload
            };
        }

        case ACTION_TYPES.INIT: {
            const { cards } = action.payload;
            return {
                ...state,
                cards
            };
        }

        case ACTION_TYPES.INJECT_TRICK_HISTORY: {
            const { tricks, winner }: { tricks: IGameState['tricks']; winner: IGameState['winner'] } = action.payload || {};
            return {
                ...state,
                tricks,
                winner
            };
        }

        case ACTION_TYPES.MAKE_PLAY: {
            const { cardId }: { cardId: ICard['id'] } = action.payload;
            const tricks =
                state.tricks.length && state.tricks[state.tricks.length - 1].length < 4 ? [...state.tricks] : [...state.tricks, []];
            tricks[tricks.length - 1].push(state.cards[cardId]);

            const card: ICard = { ...state.cards[cardId], position: 'center' };

            return {
                ...state,
                cards: {
                    ...state.cards,
                    [cardId]: card
                },
                tricks
            };
        }

        case ACTION_TYPES.RAISE_CARDS: {
            const { cardIds }: { cardIds: ICard['id'][] } = action.payload;

            let newState: IGameState = { ...state };

            cardIds.forEach(cardId => {
                newState = {
                    ...newState,
                    cards: {
                        ...newState.cards,
                        [cardId]: {
                            ...newState.cards[cardId],
                            raised: true
                        }
                    }
                };
            });

            return newState;
        }

        case ACTION_TYPES.RESET: {
            return {
                ...initialGameState
            };
        }

        case ACTION_TYPES.RESET_EXCEPT: {
            const { attributesToKeep } = action.payload;
            const newPartialState = attributesToKeep.reduce((partialState: Partial<IGameState>, attribute: keyof IGameState) => {
                return {
                    ...partialState,
                    [attribute]: state[attribute]
                };
            }, {} as Partial<IGameState>);
            return {
                ...initialGameState,
                ...newPartialState
            };
        }

        case ACTION_TYPES.RESET_PARTIAL: {
            const { attributesToReset } = action.payload;
            const newPartialState = attributesToReset.reduce((partialState: Partial<IGameState>, attribute: keyof IGameState) => {
                return {
                    ...partialState,
                    [attribute]: initialGameState[attribute]
                };
            }, {} as Partial<IGameState>);
            return {
                ...state,
                ...newPartialState
            };
        }

        case ACTION_TYPES.SET_ACTIVE_SEAT: {
            const { activeSeat }: { activeSeat?: BridgePosition } = action.payload;
            const newState = {
                ...state,
                activeBridgePosition: activeSeat,
                seatData: {
                    ...state.seatData,
                    [BridgePosition.north]: {
                        ...state.seatData[BridgePosition.north],
                        isActive: false,
                        isHighlighted: false
                    },
                    [BridgePosition.east]: {
                        ...state.seatData[BridgePosition.east],
                        isActive: false,
                        isHighlighted: false
                    },
                    [BridgePosition.south]: {
                        ...state.seatData[BridgePosition.south],
                        isActive: false,
                        isHighlighted: false
                    },
                    [BridgePosition.west]: {
                        ...state.seatData[BridgePosition.west],
                        isActive: false,
                        isHighlighted: false
                    }
                }
            };

            if (activeSeat) {
                return {
                    ...newState,
                    seatData: {
                        ...newState.seatData,
                        [activeSeat]: {
                            ...newState.seatData[activeSeat],
                            isActive: true,
                            isHighlighted: true
                        }
                    }
                };
            } else {
                return newState;
            }
        }

        case ACTION_TYPES.SET_AUCTION: {
            const { calls }: { calls: IBid[] } = action.payload;
            return {
                ...state,
                auction: calls
            };
        }

        case ACTION_TYPES.SET_BOOLEAN: {
            const { key, value }: { key: keyof IGameState; value: boolean } = action.payload || {};
            return {
                ...state,
                [key]: value
            };
        }

        case ACTION_TYPES.SET_BOARD: {
            const { board }: { board: IGameState['board'] } = action.payload;
            return {
                ...state,
                board: board
                    ? {
                          label: board.label,
                          value: board.value,
                          boardNumber: board.boardNumber
                      }
                    : undefined
            };
        }

        case ACTION_TYPES.SET_BOARD_STATS: {
            const { boardStats }: { boardStats: IGameState['boardStats'] } = action.payload;
            return {
                ...state,
                boardStats
            };
        }

        case ACTION_TYPES.SET_BPN_RECORD: {
            return {
                ...state,
                bpnRecord: action.payload
            };
        }

        case ACTION_TYPES.SET_CONTRACT: {
            const { contract, stake, declarer }: { contract: IBid; stake: IBid; declarer: BridgePosition } = action.payload;
            return {
                ...state,
                contract,
                stake,
                seatData: setUniqueSeatDataAttribute({ ...state.seatData }, { isDeclarer: true }, declarer)
            };
        }

        case ACTION_TYPES.SET_CONVENTION_CARD: {
            const { partnership, url } = action.payload;

            return {
                ...state,
                conventionCards: {
                    ...state.conventionCards,
                    [partnership]: url
                }
            };
        }

        case ACTION_TYPES.SET_DEALER: {
            const { bridgePosition }: { bridgePosition: BridgePosition } = action.payload;
            return {
                ...state,
                seatData: setUniqueSeatDataAttribute({ ...state.seatData }, { isDealer: true }, bridgePosition)
            };
        }

        case ACTION_TYPES.SET_DIRECTOR_ISSUE: {
            const { directorIssue }: { directorIssue: IGameState['directorIssue'] } = action.payload;
            return {
                ...state,
                directorIssue
            };
        }

        case ACTION_TYPES.SET_DIRECTOR_ISSUE_DONE: {
            const { directorIssueDone }: { directorIssueDone: IGameState['directorIssueDone'] } = action.payload;
            return {
                ...state,
                directorIssueDone
            };
        }

        case ACTION_TYPES.SET_DOUBLE_VALID: {
            const { doubleValid } = action.payload;
            return {
                ...state,
                doubleValid
            };
        }

        case ACTION_TYPES.SET_DUMMY_CARDS: {
            const { bridgePosition, dummyCards } = action.payload;
            return {
                ...state,
                seatData: {
                    ...state.seatData,
                    [bridgePosition]: {
                        ...state.seatData[bridgePosition],
                        dummyCards
                    }
                }
            };
        }

        case ACTION_TYPES.SET_FOREIGN_BOARD_REVIEW_DATA: {
            const { foreignBoardReviewData }: { foreignBoardReviewData: ForeignBoardReviewData } = action.payload;
            return {
                ...state,
                foreignBoardReviewData
            };
        }

        case ACTION_TYPES.SET_FOREIGN_BOARD_REVIEW_PLAYER_DATA: {
            const { playerData, index }: { playerData: ForeignBoardReviewPlayerData; index: number } = action.payload;

            if (state.foreignBoardReviewData) {
                const newPlayerData = state.foreignBoardReviewData.playerData || [];
                newPlayerData[index] = playerData;
                return {
                    ...state,
                    foreignBoardReviewData: { ...state.foreignBoardReviewData, playerData: newPlayerData }
                };
            }

            return state;
        }

        case ACTION_TYPES.SET_GAME_PHASE: {
            const { gamePhase }: { gamePhase: GamePhase } = action.payload;

            if (gamePhase === GamePhase.END) {
                const cards = { ...state.cards };

                Object.keys(cards).forEach(cardId => {
                    cards[cardId] = {
                        ...cards[cardId],
                        position: 'hand'
                    };
                });

                return {
                    ...state,
                    gamePhase,
                    cards: {
                        ...cards
                    }
                };
            }

            return {
                ...state,
                gamePhase
            };
        }

        case ACTION_TYPES.SET_GAME_RESULTS: {
            const { gameResults }: { gameResults: IGameState['gameResults'] } = action.payload;

            return {
                ...state,
                gameResults
            };
        }

        case ACTION_TYPES.SET_GAME_RESULTS_V2: {
            const { gameResultsV2 }: { gameResultsV2: IGameState['gameResultsV2'] } = action.payload;

            return {
                ...state,
                gameResultsV2
            };
        }

        case ACTION_TYPES.SET_IFRAME: {
            const iframe: string | undefined = action.payload;

            return {
                ...state,
                iframe
            };
        }

        case ACTION_TYPES.SET_IS_HIGHLIGHTED: {
            const { bridgePosition, isHighlighted }: { bridgePosition: BridgePosition; isHighlighted: boolean } = action.payload;
            const newState = {
                ...state
            };

            [BridgePosition.north, BridgePosition.east, BridgePosition.south, BridgePosition.west].forEach(_bridgePosition => {
                if (_bridgePosition !== state.activeBridgePosition) {
                    newState.seatData[_bridgePosition].isHighlighted = false;
                }
            });

            if (bridgePosition !== state.activeBridgePosition) {
                newState.seatData[bridgePosition].isHighlighted = isHighlighted;
            }

            return newState;
        }

        case ACTION_TYPES.SET_IS_INTERACTIVE: {
            const { bridgePosition, isInteractive }: { bridgePosition: BridgePosition; isInteractive: boolean } = action.payload;
            return {
                ...state,
                seatData: {
                    ...state.seatData,
                    [bridgePosition]: {
                        ...state.seatData[bridgePosition],
                        isInteractive
                    }
                }
            };
        }

        case ACTION_TYPES.SET_IS_BLOCKED_BY_TEACHER: {
            const { isBlockedByTeacher }: { isBlockedByTeacher: IGameState['isBlockedByTeacher'] } = action.payload || {};
            return {
                ...state,
                isBlockedByTeacher
            };
        }

        case ACTION_TYPES.SET_IS_VISIBLE: {
            const { bridgePosition, isVisible }: { bridgePosition: BridgePosition; isVisible: boolean } = action.payload;
            return {
                ...state,
                seatData: {
                    ...state.seatData,
                    [bridgePosition]: {
                        ...state.seatData[bridgePosition],
                        isVisible
                    }
                }
            };
        }

        case ACTION_TYPES.SET_JITSI: {
            const { jitsi }: { jitsi: IGameState['jitsi'] } = action.payload || {};
            return {
                ...state,
                jitsi
            };
        }

        case ACTION_TYPES.SET_JITSI_FULLSIZE: {
            return {
                ...state,
                jitsi: {
                    ...state.jitsi,
                    fullSize: action.payload
                }
            };
        }

        case ACTION_TYPES.SET_MY_BOARD_STAT_KEYS: {
            const { myBoardStatKeys }: { myBoardStatKeys: IGameState['myBoardStatKeys'] } = action.payload;
            return {
                ...state,
                myBoardStatKeys
            };
        }

        case ACTION_TYPES.SET_MY_BRIDGE_POSITION: {
            const { myBridgePosition } = action.payload;
            const seatData = { ...state.seatData };
            return {
                ...state,
                seatData: {
                    [BridgePosition.north]: {
                        ...seatData[BridgePosition.north],
                        ...getRotatedSeatData(myBridgePosition, BridgePosition.north)
                    },
                    [BridgePosition.east]: {
                        ...seatData[BridgePosition.east],
                        ...getRotatedSeatData(myBridgePosition, BridgePosition.east)
                    },
                    [BridgePosition.south]: {
                        ...seatData[BridgePosition.south],
                        ...getRotatedSeatData(myBridgePosition, BridgePosition.south)
                    },
                    [BridgePosition.west]: {
                        ...seatData[BridgePosition.west],
                        ...getRotatedSeatData(myBridgePosition, BridgePosition.west)
                    }
                }
            };
        }

        case ACTION_TYPES.SET_MY_HIGHLIGHTED_CARDS: {
            const { cardId } = action.payload;

            if (state.myHighlightedCards.find(_cardId => _cardId === cardId)) {
                return {
                    ...state,
                    myHighlightedCards: state.myHighlightedCards.filter(_cardId => _cardId !== cardId)
                };
            } else {
                return {
                    ...state,
                    myHighlightedCards: [...state.myHighlightedCards, cardId]
                };
            }
        }

        case ACTION_TYPES.SET_META_DATA: {
            const { metaData }: { metaData: IGameState['metaData'] } = action.payload;
            return {
                ...state,
                metaData: {
                    ...state.metaData,
                    ...metaData
                }
            };
        }

        case ACTION_TYPES.SET_PLAYER_NAMES: {
            const { playerNames } = action.payload;
            return {
                ...state,
                seatData: {
                    [BridgePosition.north]: {
                        ...state.seatData[BridgePosition.north],
                        player: playerNames[BridgePosition.north]
                    },
                    [BridgePosition.east]: {
                        ...state.seatData[BridgePosition.east],
                        player: playerNames[BridgePosition.east]
                    },
                    [BridgePosition.south]: {
                        ...state.seatData[BridgePosition.south],
                        player: playerNames[BridgePosition.south]
                    },
                    [BridgePosition.west]: {
                        ...state.seatData[BridgePosition.west],
                        player: playerNames[BridgePosition.west]
                    }
                }
            };
        }

        case ACTION_TYPES.SET_SPECTATOR: {
            const { isSpectator }: { isSpectator: IGameState['isSpectator'] } = action.payload || {};
            return {
                ...state,
                isSpectator
            };
        }

        case ACTION_TYPES.SET_TABLE_ID: {
            const { tableId }: { tableId: IGameState['tableId'] } = action.payload || {};
            return {
                ...state,
                tableId
            };
        }

        case ACTION_TYPES.SET_TRICK_COUNT: {
            const { trickCount }: { trickCount: IGameState['trickCount'] } = action.payload;
            return {
                ...state,
                trickCount
            };
        }

        case ACTION_TYPES.SET_TWITCH: {
            const { twitch }: { twitch: IGameState['twitch'] } = action.payload || {};
            return {
                ...state,
                twitch
            };
        }

        case ACTION_TYPES.SET_VIDEO: {
            const { video }: { video: Partial<IGameState['video']> } = action.payload || {};
            return {
                ...state,
                video: {
                    ...state.video,
                    ...video
                }
            };
        }

        case ACTION_TYPES.SET_VULNERABILITY: {
            const { vulnerability }: { vulnerability: IGameState['vulnerability'] } = action.payload || {};
            return {
                ...state,
                vulnerability
            };
        }

        case ACTION_TYPES.SET_WINNERS: {
            const { winner }: { winner: IGameState['winner'] } = action.payload || {};
            return {
                ...state,
                winner
            };
        }

        case ACTION_TYPES.SHOW_BUTTONS: {
            const { buttons, show }: { buttons: IGameState['buttons']; show: boolean } = action.payload || {};
            return {
                ...state,
                buttons: [...state.buttons.filter(button => buttons.indexOf(button) === -1), ...(show ? buttons : [])]
            };
        }

        case ACTION_TYPES.TIME_TO_TOURNAMENT: {
            return {
                ...state,
                timeToTournament: action.payload
            };
        }

        case ACTION_TYPES.TIME_TO_GAME_END: {
            return {
                ...state,
                timeToGameEnd: action.payload
            };
        }

        case ACTION_TYPES.UNRAISE_CARDS: {
            const { cardIds }: { cardIds: ICard['id'][] } = action.payload;

            let useCardIds = cardIds;

            if (!useCardIds) {
                useCardIds = Object.keys(state.cards);
            }

            let newState: IGameState = { ...state };

            useCardIds.forEach(cardId => {
                newState = {
                    ...newState,
                    cards: {
                        ...newState.cards,
                        [cardId]: {
                            ...newState.cards[cardId],
                            raised: false
                        }
                    }
                };
            });

            return newState;
        }

        default: {
            return state;
        }
    }
};

// Actions

const initGame = (cards: ICards) => ({
    type: ACTION_TYPES.INIT,
    payload: { cards }
});

const addChatEntry = (chatEntry: ChatEntry) => ({
    type: ACTION_TYPES.ADD_CHAT_ENTRY,
    payload: { chatEntry }
});

const appendAuction = (call: IBid) => ({
    type: ACTION_TYPES.APPEND_AUCTION,
    payload: { call }
});

const blockGameByTeacher = (isBlockedByTeacher: IGameState['isBlockedByTeacher']) => ({
    type: ACTION_TYPES.SET_IS_BLOCKED_BY_TEACHER,
    payload: { isBlockedByTeacher }
});

const clearChat = () => ({
    type: ACTION_TYPES.CLEAR_CHAT
});

const clearMyHighlightedCards = () => ({
    type: ACTION_TYPES.CLEAR_MY_HIGHLIGHTED_CARDS
});

const clearSystemChat = () => ({
    type: ACTION_TYPES.CLEAR_SYSTEM_CHAT
});

const closeTrick = (winner: BridgePosition) => ({
    type: ACTION_TYPES.CLOSE_TRICK,
    payload: { winner }
});

const dealHands = (hands: { [bridgePosition: string]: Array<ICard['id']> }) => ({
    type: ACTION_TYPES.DEAL_HANDS,
    payload: { hands }
});

const displayActiveSeat = (activeSeat?: BridgePosition) => ({
    type: ACTION_TYPES.DISPLAY_ACTIVE_SEAT,
    payload: { activeSeat }
});

const enableDummy = (dummyEnabled: boolean) => ({
    type: ACTION_TYPES.ENABLE_DUMMY,
    payload: { dummyEnabled }
});

const injectTrickHistory = (tricks: IGameState['tricks'], winner: IGameState['winner']) => ({
    type: ACTION_TYPES.INJECT_TRICK_HISTORY,
    payload: { tricks, winner }
});

const forceHideMe = (forcedHideMe: boolean) => ({
    type: ACTION_TYPES.FORCE_HIDE_ME,
    payload: forcedHideMe
});

const hideButtons = (buttons: PossibleButton[]) => ({
    type: ACTION_TYPES.SHOW_BUTTONS,
    payload: { buttons, show: false }
});

const makePlay = (cardId: ICard['id']) => ({
    type: ACTION_TYPES.MAKE_PLAY,
    payload: {
        cardId
    }
});

const raiseCards = (cardIds: ICard['id'][]) => ({
    type: ACTION_TYPES.RAISE_CARDS,
    payload: {
        cardIds
    }
});

const resetGame = () => ({
    type: ACTION_TYPES.RESET
});

const resetGameExcept = (attributesToKeep: Array<keyof IGameState>) => ({
    type: ACTION_TYPES.RESET_EXCEPT,
    payload: { attributesToKeep }
});

const resetGamePartials = (attributesToReset: Array<keyof IGameState>) => ({
    type: ACTION_TYPES.RESET_PARTIAL,
    payload: { attributesToReset }
});

const setActiveSeat = (activeSeat?: BridgePosition) => ({
    type: ACTION_TYPES.SET_ACTIVE_SEAT,
    payload: { activeSeat }
});

const setAuction = (calls: IBid[]) => ({
    type: ACTION_TYPES.SET_AUCTION,
    payload: { calls }
});

const setBoard = (board?: IGameState['board']) => ({
    type: ACTION_TYPES.SET_BOARD,
    payload: { board }
});

const setBoardStats = (boardStats?: IGameState['boardStats']) => ({
    type: ACTION_TYPES.SET_BOARD_STATS,
    payload: { boardStats }
});

const setBpnRecord = (bpnRecord: string) => ({
    type: ACTION_TYPES.SET_BPN_RECORD,
    payload: bpnRecord
});

const setContract = ({
    contract,
    stake,
    declarer
}: {
    contract: IGameState['contract'];
    stake: IGameState['stake'];
    declarer: BridgePosition;
}) => ({
    type: ACTION_TYPES.SET_CONTRACT,
    payload: { contract, stake, declarer }
});

const setConventionCard = (key: 'ns' | 'ew' | 'url', url: string) => ({
    type: ACTION_TYPES.SET_CONVENTION_CARD,
    payload: { partnership: key, url }
});

const setConventionCardUrl = (conventionCardUrlSet: boolean) => ({
    type: ACTION_TYPES.SET_BOOLEAN,
    payload: { key: 'conventionCardUrlSet', value: conventionCardUrlSet }
});

const setDirectorIssue = (directorIssue: string) => ({
    type: ACTION_TYPES.SET_DIRECTOR_ISSUE,
    payload: { directorIssue }
});

const setDirectorIssueDone = (directorIssueDone: boolean) => ({
    type: ACTION_TYPES.SET_DIRECTOR_ISSUE_DONE,
    payload: { directorIssueDone }
});

const setDealer = (bridgePosition: BridgePosition) => ({
    type: ACTION_TYPES.SET_DEALER,
    payload: { bridgePosition }
});

const setDoubleValid = (doubleValid: 1 | 2 | 4) => ({
    type: ACTION_TYPES.SET_DOUBLE_VALID,
    payload: { doubleValid }
});

const setDummyCards = (bridgePosition: BridgePosition, dummyCards: number) => ({
    type: ACTION_TYPES.SET_DUMMY_CARDS,
    payload: { bridgePosition, dummyCards }
});

const setEnabledBiddingBox = (isEnabled: boolean) => ({
    type: ACTION_TYPES.BIDDING_DISABLE_ALL,
    payload: isEnabled
});

const setForeignBoardReviewData = (foreignBoardReviewData: IGameState['foreignBoardReviewData']) => ({
    type: ACTION_TYPES.SET_FOREIGN_BOARD_REVIEW_DATA,
    payload: { foreignBoardReviewData }
});

const setForeignBoardReviewPlayerData = (playerData: ForeignBoardReviewPlayerData, index: number) => ({
    type: ACTION_TYPES.SET_FOREIGN_BOARD_REVIEW_PLAYER_DATA,
    payload: { playerData, index }
});

const setGamePhase = (gamePhase: GamePhase) => ({
    type: ACTION_TYPES.SET_GAME_PHASE,
    payload: { gamePhase }
});

const setGameResults = (gameResults?: IGameResults) => ({
    type: ACTION_TYPES.SET_GAME_RESULTS,
    payload: { gameResults }
});

const setGameResultsV2 = (gameResultsV2?: IGameResultsV2) => ({
    type: ACTION_TYPES.SET_GAME_RESULTS_V2,
    payload: { gameResultsV2 }
});

const setIframe = (src: string | undefined) => ({
    type: ACTION_TYPES.SET_IFRAME,
    payload: src
});

const setIsHighlighted = (bridgePosition: BridgePosition, isHighlighted: boolean) => ({
    type: ACTION_TYPES.SET_IS_HIGHLIGHTED,
    payload: { bridgePosition, isHighlighted }
});

const setIsInteractive = (bridgePosition: BridgePosition, isInteractive: boolean) => ({
    type: ACTION_TYPES.SET_IS_INTERACTIVE,
    payload: { bridgePosition, isInteractive }
});

const setIsVisible = (bridgePosition: BridgePosition, isVisible: boolean) => ({
    type: ACTION_TYPES.SET_IS_VISIBLE,
    payload: { bridgePosition, isVisible }
});

const setJitsi = (jitsi: IGameState['jitsi']) => ({
    type: ACTION_TYPES.SET_JITSI,
    payload: { jitsi }
});

const setJitsiFullSize = (isFullSize: boolean) => ({
    type: ACTION_TYPES.SET_JITSI_FULLSIZE,
    payload: isFullSize
});

const setMetaData = (metaData: IGameState['metaData']) => ({
    type: ACTION_TYPES.SET_META_DATA,
    payload: { metaData }
});

const setMyBoardStatKeys = (myBoardStatKeys?: IGameState['myBoardStatKeys']) => ({
    type: ACTION_TYPES.SET_MY_BOARD_STAT_KEYS,
    payload: { myBoardStatKeys }
});

const setMyBridgePosition = (myBridgePosition: BridgePosition) => ({
    type: ACTION_TYPES.SET_MY_BRIDGE_POSITION,
    payload: { myBridgePosition }
});

const setMyHighlightedCards = (cardId: ICard['id']) => ({
    type: ACTION_TYPES.SET_MY_HIGHLIGHTED_CARDS,
    payload: { cardId }
});

const setPlayerNames = (playerNames: { [bridgePosition: string]: string }) => ({
    type: ACTION_TYPES.SET_PLAYER_NAMES,
    payload: { playerNames }
});

const setSpectator = (isSpectator: IGameState['isSpectator']) => ({
    type: ACTION_TYPES.SET_BOOLEAN,
    payload: { key: 'isSpectator', value: isSpectator }
});

const setTableId = (tableId: IGameState['tableId']) => ({
    type: ACTION_TYPES.SET_TABLE_ID,
    payload: { tableId }
});

const setTrickCount = (trickCount: IGameState['trickCount']) => ({
    type: ACTION_TYPES.SET_TRICK_COUNT,
    payload: { trickCount }
});

const setTimeToTournament = (timeToTournament: IGameState['timeToTournament']) => ({
    type: ACTION_TYPES.TIME_TO_TOURNAMENT,
    payload: timeToTournament
});

const setTimeToGameEnd = (timeToGameEnd: IGameState['timeToGameEnd']) => ({
    type: ACTION_TYPES.TIME_TO_GAME_END,
    payload: timeToGameEnd
});

const setTwitch = (twitch: IGameState['twitch']) => ({
    type: ACTION_TYPES.SET_TWITCH,
    payload: { twitch }
});

const setVideo = (video: Partial<IGameState['video']>) => ({
    type: ACTION_TYPES.SET_VIDEO,
    payload: { video }
});

const setVulnerability = (vulnerability: IGameState['vulnerability']) => ({
    type: ACTION_TYPES.SET_VULNERABILITY,
    payload: { vulnerability }
});

const setWinners = (winner: IGameState['winner']) => ({
    type: ACTION_TYPES.SET_WINNERS,
    payload: { winner }
});

const showAuctionBox = (show: boolean) => ({
    type: ACTION_TYPES.SET_BOOLEAN,
    payload: { key: 'showAuctionBox', value: show }
});

const showButtons = (buttons: PossibleButton[]) => ({
    type: ACTION_TYPES.SHOW_BUTTONS,
    payload: { buttons, show: true }
});

const showChatSendForm = (show: boolean) => ({
    type: ACTION_TYPES.SET_BOOLEAN,
    payload: { key: 'showChatSendForm', value: show }
});

const showClosedTricks = (show: boolean) => ({
    type: ACTION_TYPES.SET_BOOLEAN,
    payload: { key: 'showClosedTricks', value: show }
});

const showTrickHistory = (show: boolean) => ({
    type: ACTION_TYPES.SET_BOOLEAN,
    payload: { key: 'showTrickHistory', value: show }
});

const showInvites = (show: boolean) => ({
    type: ACTION_TYPES.SET_BOOLEAN,
    payload: { key: 'showInvites', value: show }
});

const unRaiseCards = (cardIds?: ICard['id'][]) => ({
    type: ACTION_TYPES.UNRAISE_CARDS,
    payload: {
        cardIds
    }
});

export {
    addChatEntry,
    appendAuction,
    clearChat,
    clearMyHighlightedCards,
    clearSystemChat,
    closeTrick,
    dealHands,
    displayActiveSeat,
    enableDummy,
    forceHideMe,
    hideButtons,
    initGame,
    injectTrickHistory,
    blockGameByTeacher,
    makePlay,
    raiseCards,
    resetGame,
    resetGamePartials,
    resetGameExcept,
    setActiveSeat,
    setAuction,
    setBoard,
    setBoardStats,
    setBpnRecord,
    setContract,
    setConventionCard,
    setConventionCardUrl,
    setDealer,
    setDirectorIssue,
    setDirectorIssueDone,
    setDoubleValid,
    setDummyCards,
    setEnabledBiddingBox,
    setForeignBoardReviewData,
    setForeignBoardReviewPlayerData,
    setGamePhase,
    setGameResults,
    setGameResultsV2,
    setIframe,
    setIsHighlighted,
    setIsInteractive,
    setIsVisible,
    setJitsi,
    setJitsiFullSize,
    setMetaData,
    setMyBoardStatKeys,
    setMyBridgePosition,
    setMyHighlightedCards,
    setPlayerNames,
    setTableId,
    setTimeToTournament,
    setTimeToGameEnd,
    setTrickCount,
    setTwitch,
    setSpectator,
    setVideo,
    setVulnerability,
    setWinners,
    showAuctionBox,
    showChatSendForm,
    showButtons,
    showClosedTricks,
    showTrickHistory,
    showInvites,
    unRaiseCards
};
