import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { BridgePosition, IBid } from '../../_models/models';
import { getMySeatData, isVulnerable } from '../../_utils/helper';
import { IRootState } from '../../store/_root.reducer';
import output, { clickBid, makeCall } from '../../store/output.reducer';

import './auction-box.scss';
import { Call } from '../call/call';
import { ForeignBoardReviewData, ForeignBoardReviewPlayerData } from '../../store/game.reducer';

const rotationHelper = [
    BridgePosition.north,
    BridgePosition.east,
    BridgePosition.south,
    BridgePosition.west,
    BridgePosition.north,
    BridgePosition.east,
    BridgePosition.south,
    BridgePosition.west
];

export interface IAuctionBoxProps extends StateProps, DispatchProps {
    foreignBoardData?: Pick<ForeignBoardReviewData, 'seatData' | 'vulnerability' | 'showAuctionBox'> & {
        auction: ForeignBoardReviewPlayerData['auction'];
    };
}

const AuctionBox: FunctionComponent<IAuctionBoxProps> = ({ game, app, foreignBoardData, clickBid }) => {
    const { t } = useTranslation();
    const [lastAuctionLength, setLastAuctionLength] = useState<number>(0);
    const currentBidder = useRef(null);
    const { layout } = app;

    let { seatData, auction, vulnerability, showAuctionBox } = game;

    if (foreignBoardData) {
        seatData = foreignBoardData.seatData;
        vulnerability = foreignBoardData.vulnerability;
        showAuctionBox = foreignBoardData.showAuctionBox;
        auction = foreignBoardData.auction;
    }

    const { selectedCardSet } = layout;

    const mySeatData = getMySeatData(seatData);

    if (lastAuctionLength !== auction.length) {
        setLastAuctionLength(auction.length);
        setTimeout(() => {
            if (currentBidder && currentBidder.current) {
                //@ts-ignore
                // currentBidder.current.scrollIntoView({
                //     behavior: 'smooth'
                // });
            }
        }, 100);
    }

    if (!mySeatData) {
        return null;
    }

    const index = rotationHelper.indexOf(mySeatData.bridgePosition);
    const auctionRotation = rotationHelper.slice(index + 1, index + 5);
    let dealerIndex = 0;

    const renderBiddingHistory = () => {
        const shiftedBiddingsHistory: Array<IBid | undefined> = [undefined, undefined, undefined, undefined];
        let biddingHistory = shiftedBiddingsHistory.slice(0, dealerIndex).concat(auction);

        const { length } = auction;

        if (
            !(
                auction.length >= 4 &&
                auction[length - 1].strain === 'pass' &&
                auction[length - 2].strain === 'pass' &&
                auction[length - 3].strain === 'pass'
            )
        ) {
            biddingHistory = biddingHistory.concat([undefined]);
        }

        return biddingHistory
            .map((call, index) => {
                if (call) {
                    return (
                        <td key={`call-${index}`} onClick={() => clickBid(index - dealerIndex)}>
                            <Call key={`${call.level}-${call.strain}-${index}`} call={call} selectedCardSet={selectedCardSet} />
                        </td>
                    );
                } else {
                    const props = {
                        key: `empty-${index}`,
                        className: 'empty',
                        ...(index === biddingHistory.length - 1 ? { id: 'currentBidder' } : {}),
                        ...(index === biddingHistory.length - 1 ? { ref: currentBidder } : {})
                    };
                    return <td {...props} />;
                }
            })
            .reduce((prev: JSX.Element[][], curr: JSX.Element, index) => {
                let _prev = [...prev];
                if (index % 4 === 0) {
                    _prev.push([]);
                }
                _prev[_prev.length - 1].push(curr);
                return _prev;
            }, [])
            .map((tr, index) => <tr key={`tr-${index}`}>{tr}</tr>);
    };

    return (
        <section className={`AuctionBox vulnerability-${vulnerability} ${showAuctionBox ? 'showAuctionBox' : 'hideAuctionBox'}`}>
            <div className="thead">
                <table className="auction">
                    <thead className="bridgePositions">
                        <tr>
                            {auctionRotation.map((bridgePosition, index) => {
                                const currentSeatData = seatData[bridgePosition];
                                if (currentSeatData.isDealer) {
                                    dealerIndex = index;
                                }
                                return (
                                    <th
                                        key={`bridgePosition-${index}`}
                                        className={`bridgePosition ${isVulnerable(bridgePosition, vulnerability) ? 'vulnerable' : ''}`}
                                    >
                                        {t(`bridgePosition.${bridgePosition}`)}
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="tbody">
                <table className="auction">
                    <tbody className="setWidths">
                        <tr>
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    </tbody>
                    <tbody className="calls">{renderBiddingHistory()}</tbody>
                </table>
            </div>
        </section>
    );
};

const mapStateToProps = ({ game, app, output }: IRootState) => ({
    game,
    app,
    output
});

const mapDispatchToProps = {
    clickBid
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuctionBox);
